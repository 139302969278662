import React from 'react'

export default () => {
  return (
    <svg
      id="toilet-icon"
      height="36"
      viewBox="0 0 44 44"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(6.000000, 9.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <path
            className="svg-block"
            d="M25.4991005,5 C26.8805628,5 28,3.88143607 28,2.50046537 C28,1.11856393 26.8805007,0 25.4991005,0 C24.1183827,0 23,1.11856393 23,2.50046537 C23,3.88143607 24.1183827,5 25.4991005,5 Z"
          />
          <path
            className="svg-block"
            d="M31.8999519,14.7858741 L28.2274142,6.63595953 C28.0290748,6.19226382 27.5796162,6.00058262 27.1240235,6.00058262 C25.538075,6.00058262 24.6181994,6 22.8757647,6 C22.420172,6 21.9708412,6.19168121 21.7717989,6.63595953 L18.1001558,14.7858741 C17.8468002,15.3466113 18.0908266,16.0084002 18.6436722,16.2642341 C18.7921711,16.3324003 18.9476989,16.3653506 19.1000956,16.3653506 C19.5185636,16.3653506 19.9186929,16.1228522 20.1029109,15.7124304 L21.8783808,11.7708925 L21.8783808,16.0168158 C21.8783808,16.0241956 21.8746108,16.0306691 21.8746108,16.0381137 L21.8746108,25.6610799 C21.8746108,26.4007454 22.4661785,27 23.1958298,27 C23.9257367,27 24.5170488,26.4007454 24.5170488,25.6610799 L24.5170488,17.6832964 L25.4818448,17.6832964 L25.4818448,25.6610799 C25.4818448,26.4007454 26.0732208,27 26.8029999,27 C27.5326512,27 28.1242189,26.4007454 28.1242189,25.6610799 L28.1242189,16.0381137 C28.1242189,16.0322228 28.1214074,16.028015 28.1214074,16.0221241 L28.1214074,11.7708925 L29.897069,15.7124304 C30.0810314,16.1227874 30.4814163,16.3653506 30.8992453,16.3653506 C31.0518337,16.3653506 31.2078088,16.3324003 31.3565633,16.2642341 C31.909281,16.0084002 32.1530519,15.3466113 31.8999519,14.7858741 Z"
          />
          <path
            className="svg-block"
            d="M6.50055833,5 C7.88148589,5 9,3.88143607 9,2.50046537 C9,1.11856393 7.88148589,0 6.50055833,0 C5.1190104,0 4,1.11856393 4,2.50046537 C4,3.88143607 5.1190104,5 6.50055833,5 Z"
          />
          <path
            className="svg-block"
            d="M10.2279308,6.64405625 C10.0466529,6.24072031 9.65579044,6.00834756 9.24518357,6.00038826 L4.75234181,6 C4.34167104,6.00841227 3.95317279,6.24072031 3.77234218,6.64412096 L0.100075199,14.7906917 C-0.153087644,15.3512076 0.0907461295,16.0127355 0.643461576,16.2684685 C0.792087718,16.3366079 0.9480621,16.3695451 1.100586,16.3695451 C1.51854112,16.3695451 1.91879656,16.1271424 2.10288595,15.7168825 L3.24556922,13.1816187 L2.25055362,17.925556 C2.21687951,18.0853892 2.25662391,18.2516285 2.35777403,18.3785244 C2.45962702,18.5056143 2.61202313,18.5791246 2.77291764,18.5791246 L3.87572873,18.5791246 L3.87572873,25.6615433 C3.87572873,26.400917 4.4671662,26.9999353 5.19700618,26.9999353 C5.92671837,26.9999353 6.51821974,26.400917 6.51821974,25.6615433 L6.51821974,18.5791893 L7.48288392,18.5791893 L7.48288392,25.661608 C7.48288392,26.4009817 8.07444919,27 8.80416137,27 C9.53387356,27 10.1253749,26.4009817 10.1253749,25.661608 L10.1253749,18.5791893 L11.2243522,18.5791893 C11.3851828,18.5791893 11.5377706,18.5056791 11.6389846,18.3785891 C11.740582,18.2516932 11.7805181,18.0853892 11.7467162,17.9256208 L10.7494642,13.169971 L11.8970037,15.7168825 C12.0812208,16.1270776 12.4814124,16.3695451 12.8998148,16.3695451 C13.0525304,16.3695451 13.207738,16.3366079 13.356428,16.2684685 C13.9093352,16.0127355 14.153105,15.3512076 13.8998783,14.7906917 L10.2279308,6.64405625 Z"
          />
          <path
            className="svg-block"
            d="M16,2 C15.4471757,2 15,2.41723565 15,2.93153584 L15,25.0685317 C15,25.5828994 15.4471757,26 16,26 C16.5526068,26 17,25.5828994 17,25.0685317 L17,2.93153584 C17,2.4173032 16.5526068,2 16,2 Z"
          />
        </g>
      </g>
    </svg>
  )
}
