// react libs
import { InfoWindow } from '@react-google-maps/api'
import moment from 'moment'
import React, { FunctionComponent } from 'react'

import { IMapPlace, IMapPlaceSensor } from '../types/mapPlace'

import BbqIcon from './icons/bbq'
import DrinkFountain from './icons/drinkfountain'
import PicnicIcon from './icons/picnic'
import PlaygroundIcon from './icons/playground'
import RestroomIcon from './icons/restroom'
import SportsIcon from './icons/sports'

// types

interface IInfoWindowProps {
  site: IMapPlace
}

const styles = {
  sensorValue: {
    margin: '10px 0 5px 0',
  },
  sensorReading: {
    margin: 0,
  },
}

// InfoWindow component
const MapInfoWindow: FunctionComponent<IInfoWindowProps> = ({ site }) => {
  const position = {
    lat: site.siteLocation.latitude,
    lng: site.siteLocation.longitude,
  }

  const [airTempSensor] = site.sensors.filter((sensor: IMapPlaceSensor) =>
    sensor.type.includes('Temperature'),
  )

  const airTempSensorReading = airTempSensor ? (
    <div>
      <h3 style={styles.sensorValue}>
        Air Temperature: {airTempSensor.value}°C
      </h3>
      <p style={styles.sensorReading}>
        Last Reading: {moment(airTempSensor.lastReading).fromNow()}
      </p>
    </div>
  ) : null

  const backgroundImageCSS = url => {
    return {
      backgroundImage: `url(${url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100%',
      width: '100%',
      minHeight: '220px',
      minWidth: '250px',
      maxWidth: '308px',
    }
  }

  return (
    <InfoWindow position={position}>
      <div className="map-info-window-container">
        <div style={backgroundImageCSS(site.siteImage)} />
        <div className="scrollbar content-contatiner">
          <div style={{ padding: '10px' }}>
            <h1>{site.siteName}</h1>
            {airTempSensorReading}
            <div className="icon-row">
              {site.facilities.playground && (
                <div className="icon-wrapper">
                  <PlaygroundIcon />
                </div>
              )}
              {site.facilities.toilet && (
                <div className="icon-wrapper">
                  <RestroomIcon />
                </div>
              )}
              {site.facilities.drinkingFountain && (
                <div className="icon-wrapper">
                  <DrinkFountain />
                </div>
              )}
              {site.facilities.bbq && (
                <div className="icon-wrapper">
                  <BbqIcon />
                </div>
              )}
              {site.facilities.picnicArea && (
                <div className="icon-wrapper">
                  <PicnicIcon />
                </div>
              )}
              {site.facilities.sportsFacilities && (
                <div className="icon-wrapper">
                  <SportsIcon />
                </div>
              )}
            </div>
            {/* may need to verify html is valid */}
            <div
              dangerouslySetInnerHTML={{ __html: site.siteDescription }}
              style={{ margin: '10px 0' }}
            />
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={site.websiteUrl} rel="noopener noreferrer" target="_blank">
              {site.websiteText || 'Visit Council Website'}
            </a>
          </div>
        </div>
      </div>
    </InfoWindow>
  )
}

export default MapInfoWindow
